<template>
  <auth-layout>
    <v-container>
      <checkout-steps :step="2"></checkout-steps>
      <page-header class="mt-6">
        <page-title> Detalii livrare </page-title>
      </page-header>
      <form
        class="md:w-2/3 mx-auto"
        action=""
        method="POST"
        @submit.prevent="onSubmit"
        @keydown="form.errors.clear($event.target.name)"
      >
        <div class="mb-6" v-text="form.message"></div>

        <div class="mb-6 flex flex-row space-x-6">
          <div class="flex-1">
            <v-label for="name"> Prenume </v-label>
            <v-input type="text" name="name" v-model="form.name"></v-input>
            <span
              class="text-red-500"
              v-if="form.errors.has('name')"
              v-text="form.errors.get('name')"
            ></span>
          </div>
          <div class="flex-1">
            <v-label for="last_name"> Nume </v-label>
            <v-input
              type="text"
              name="last_name"
              v-model="form.last_name"
            ></v-input>
            <span
              class="text-red-500"
              v-if="form.errors.has('last_name')"
              v-text="form.errors.get('last_name')"
            ></span>
          </div>
        </div>

        <div class="mb-6">
          <v-label for="address"> Strada </v-label>
          <v-input type="text" name="address" v-model="form.address"></v-input>
          <span
            class="text-red-500"
            v-if="form.errors.has('address')"
            v-text="form.errors.get('address')"
          ></span>
        </div>
        <div class="mb-6 flex flex-row space-x-6">
          <div class="flex-1">
            <v-label for="city"> Localitate </v-label>
            <v-input type="text" name="city" v-model="form.city"></v-input>
            <span
              class="text-red-500"
              v-if="form.errors.has('city')"
              v-text="form.errors.get('city')"
            ></span>
          </div>
          <div class="flex-1">
            <v-label for="province"> Judet </v-label>
            <v-input
              type="text"
              name="province"
              v-model="form.province"
            ></v-input>
            <span
              class="text-red-500"
              v-if="form.errors.has('province')"
              v-text="form.errors.get('province')"
            ></span>
          </div>
        </div>
        <div class="mb-6 flex flex-row space-x-6">
          <div class="flex-1">
            <v-label for="phone"> Telefon </v-label>
            <v-input type="text" name="phone" v-model="form.phone"></v-input>
            <span
              class="text-red-500"
              v-if="form.errors.has('phone')"
              v-text="form.errors.get('phone')"
            ></span>
          </div>
          <div class="flex-1">
            <v-label for="email"> E-mail </v-label>
            <v-input type="email" name="email" v-model="form.email"></v-input>
            <span
              class="text-red-500"
              v-if="form.errors.has('email')"
              v-text="form.errors.get('email')"
            ></span>
          </div>
        </div>

        <div class="mt-10 sm:w-1/2 text-center mx-auto">
          <button-outline-primary
            :disabled="form.isLoading"
            class="block w-full"
          >
            Continua
          </button-outline-primary>
          <router-link
            class="block text-gray-400 mt-3"
            :to="{ name: 'billing.show' }"
          >
            Inapoi la Pasul 1
          </router-link>
        </div>
      </form>
    </v-container>
  </auth-layout>
</template>

<script>
import Form from "../core/Form";
import ButtonOutlinePrimary from "../components/ButtonOutlinePrimary.vue";
import PageHeader from "../components/PageHeader.vue";
import VContainer from "../components/VContainer.vue";
import VInput from "../components/ui/VInput.vue";
import VLabel from "../components/ui/VLabel.vue";
import AuthLayout from "../layout/AuthLayout.vue";
import CheckoutSteps from "../components/CheckoutSteps.vue";
import PageTitle from "../components/PageTitle.vue";

export default {
  components: {
    VContainer,
    PageHeader,
    ButtonOutlinePrimary,
    VInput,
    VLabel,
    AuthLayout,
    CheckoutSteps,
    PageTitle,
  },
  data() {
    return {
      form: new Form({
        name: "",
        address: "",
        city: "",
        province: "",
        phone: "",
        postalcode: "",
      }),
    };
  },
  methods: {
    onSubmit() {
      this.$router.push({ name: "checkout-summary.show" });
    },
  },
};
</script>

<style></style>
